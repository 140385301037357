import { defineStore } from 'pinia'
import { type ICategory, type  ICategoriesState } from '~/types/ICategoriesState'
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'

export const useCategoriesStore = defineStore('categories', {
  state: (): ICategoriesState => ({
    categories: [],
    pending: false,
    error: null,
  }),
  actions: {
    async fetch () {
      const { data, pending, error, execute } = await useAuthenticatedFetch<ICategory[]>(`/api/v1/shop/categories`, {
        immediate: false,
        watch: false
      })
      this.pending = pending.value
      this.error = error
      await execute()
      this.pending = pending.value
      this.error = error
      if (Array.isArray(data.value)) {
        this.categories = data.value.sort((a, b) => a.priority - b.priority)
      }
    }
  }
})
